export const booleanFilter = [
  { text: 'Ano', value: 'true' },
  { text: 'Ne', value: 'false' },
]

export const paymentStatusesFilter = [{
  text: 'čeká na zaplacení', value: 'PENDING',
}, {
  text: 'autorizována', value: 'AUTHORIZED',
}, {
  text: 'částečně uhrazena', value: 'PARTIALLY',
}, {
  text: 'zaplaceno', value: 'PAID',
}, {
  text: 'přeplatek', value: 'OVERPAID',
}, {
  text: 'storno', value: 'CANCELLED',
}]

export const statusesFilter = [{
  text: 'přijatá', value: 'RCD',
}, {
  text: 'vyřizuje se', value: 'PIG',
}, {
  text: 'odeslaná', value: 'SET',
}, {
  text: 'připravena k vyzvednutí', value: 'RFP',
}, {
  text: 'připravuje se k odeslání', value: 'RSN',
}, {
  text: 'vyřízená', value: 'CPD',
}, {
  text: 'storno', value: 'CCD',
}]

export const trackStatusesFilter = [{
  value: 'ERROR_SENDER',
  text: 'Chyba na straně odesilatele',
}, {
  value: 'ERROR_RECIPIENT',
  text: 'Chyba na straně příjemce',
}, {
  value: 'ERROR_SHIPPER',
  text: 'Chyba u dopravce',
}, {
  value: 'COD_PAID',
  text: 'Dobírka byla připsána na účet odesilatele',
}, {
  value: 'DELIVERED_BACK_TO_SENDER',
  text: 'Doručena zpět odesilateli',
}, {
  value: 'DELIVERED_TO_ADDRESS',
  text: 'Doručeno na adresu',
}, {
  text: 'Objednáno',
  value: 'ORDERED',
}, {
  value: 'HANDED_TO_FINAL_SHIPPER',
  text: 'Předána koncovému dopravci',
}, {
  value: 'READY_TO_PICK_UP',
  text: 'Připravena k vyzvednutí',
}, {
  value: 'CANCELLATION_BY_SHIPPER',
  text: 'Storno ze strany dopravce',
}, {
  value: 'CANCELLATION_BY_SENDER',
  text: 'Storno ze strany odesilatele',
}, {
  value: 'CANCELLATION_BY_RECIPIENT',
  text: 'Storno ze strany příjemce',
}, {
  value: 'TRANSIT',
  text: 'Transit',
}, {
  value: 'PICKED_UP_FROM_SENDER',
  text: 'Vyzvednuta u odesilatele',
}, {
  value: 'PICKUP_ON_DELIVERY_POINT',
  text: 'Vyzvednutí zásilky na výdejním místě',
}, {
  value: 'SEND_BACK_TO_SENDER',
  text: 'Zpět na cestě k odesilateli',
}]
