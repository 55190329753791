<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Objednávky - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'" class="mr-2">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item @click="showModalStatus(status)" :key="'st' + status.text" v-for="status in statuses" :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                {{ status.text | capitalize({ onlyFirstLetter: true }) }}
              </a-menu-item>
              <a-menu-divider></a-menu-divider>
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ isAllSelected === true ? 'Vše' : selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <my-balikobot-parcels
            :selected-ids="selectedRowKeys"
          ></my-balikobot-parcels>
        </div>
      </div>
      <my-table
        v-on:changeIsAllSelected="setIsAllSelected"
        v-on:showNote="showNote"
        v-on:changeSelectedRowKeys="changeSelectedRowKeys"
        v-on:confirm-delete="confirmDelete"
        v-on:fetch="fetch"
        :loading="loading"
        default-order="desc"
        :columns="columns"
        :data-source="orders"
        id-name="odr_id"
        :table-name="tableName"
        detail-url="/ecommerce/order/detail/"
      >
      </my-table>
    </div>
    <a-modal v-model="isModalStatusVisible" :confirmLoading="loading" v-if="selectedStatus !== null">
      <p>Opravdu si přejete upravit status na <strong>{{ selectedStatus.text }}</strong> pro tyto položky?</p>
      <div v-if="selectedRowKeys.length > 0">
        {{ selectedRowKeys }}
      </div>.
      <template slot="footer">
        <a-button key="back" @click="cancelModalStatus">Zrušit</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="confirmEmail(selectedRowKeys, selectedStatus.status)">
          Potvrdit
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import MyBalikobotParcels from '@/components/MyComponents/Balikobot/my-balikobot-parcels'
import MyTable from '@/components/MyComponents/Table/my-table'
import {
  booleanFilter,
  statusesFilter,
  paymentStatusesFilter,
  trackStatusesFilter,
} from '@/components/MyComponents/Table/filters'

export default {
  components: { MyBalikobotParcels, MyTable, ActionTools },
  data: function () {
    return {
      isAllSelected: false,
      tableName: 'order',
      selectedStatus: null,
      isModalStatusVisible: false,
      selectedRowKeys: [],
      image: {
        errors: [],
      },
      loading: false,
      routerOptions: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'odr_id',
          defaultSortOrder: 'descend',
          sorter: true,
          scopedSlots: {
            customRender: 'odr_id',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          pattern: '[ROK-MĚSÍC-DEN] 2021-01-07',
          sorter: true,
          scopedSlots: {
            customRender: 'dates',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
        },
        {
          title: 'Zákazník',
          dataIndex: 'customer',
          sorter: true,
          scopedSlots: {
            customRender: 'customRender',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
        },
        {
          title: 'Firma',
          dataIndex: 'is_company',
          sorter: true,
          scopedSlots: {
            customRender: 'active',
          },
          filters: booleanFilter,
        },
        {
          title: 'Náš stav',
          dataIndex: 'status',
          sorter: true,
          scopedSlots: {
            customRender: 'status',
          },
          filters: statusesFilter,
        },
        {
          title: 'Balíkobot stav',
          dataIndex: 'last_track_status',
          sorter: true,
          scopedSlots: {
            customRender: 'trackStatus',
          },
          filters: trackStatusesFilter,
        },
        {
          title: 'Stav platby',
          dataIndex: 'payment_status',
          sorter: true,
          scopedSlots: {
            customRender: 'payment_status',
          },
          filters: paymentStatusesFilter,
        },
        {
          title: 'Odstraněna',
          dataIndex: 'odr_is_deleted',
          sorter: true,
          scopedSlots: {
            customRender: 'deleted',
          },
          filters: booleanFilter,
        },
        {
          title: 'Poznámka',
          dataIndex: 'note',
          scopedSlots: {
            customRender: 'note',
          },
          filters: booleanFilter,
        },
        {
          title: 'Platba',
          dataIndex: 'phe_name',
          sorter: true,
          scopedSlots: {
            customRender: 'customRender',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
        },
        {
          title: 'Telefon',
          dataIndex: 'telephone_number',
          pattern: '[Bez předvolby] 604 xxx xxx',
          sorter: true,
          scopedSlots: {
            customRender: 'telephoneRender',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
        },
        {
          title: 'Cena s DPH',
          dataIndex: 'order_total_vat_rounded',
          sorter: true,
          scopedSlots: {
            customRender: 'price',
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    orders: function () {
      return this.$store.getters['order/filterItems']
    },
    statuses: function () {
      return this.$store.getters['order/getStatuses']
    },
  },
  methods: {
    setIsAllSelected(value) {
      this.isAllSelected = value
    },
    changeSelectedRowKeys(values) {
      this.selectedRowKeys = values
    },
    cancelModalStatus() {
      this.isModalStatusVisible = false
    },
    showModalStatus(status) {
      this.isModalStatusVisible = true
      this.selectedStatus = status
    },
    showNote(text) {
      const h = this.$createElement
      this.$modal.info({
        title: 'Poznámka zákazníka',
        content: h('div', {}, [
          h('p', text),
        ]),
        closable: true,
        onOk() {},
      })
    },
    handleRefresh() {
      this.fetch()
    },
    fetch(params) {
      this.loading = true
      this.$store.dispatch('order/getList', this.$store.getters['pagination/getQuery'](params, 'odr_id', 'desc'))
        .then((response) => {
          const pagination = this.$store.getters['pagination/getDefault']
          pagination.current = response.current_page
          pagination.total = response.total
          this.$store.commit('pagination/SET_PAGINATION', { name: this.tableName, item: pagination })
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmEmail(items, status) {
      this.loading = true
      this.$store.dispatch('order/status', { status: status, items: items, all: this.isAllSelected })
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.$store.commit('myTable/SET_KEYS', {
              name: this.tableName,
              items: items.filter(x => !this.selectedRowKeys.includes(x))
                .concat(this.selectedRowKeys.filter(x => !items.includes(x))),
            })
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isModalStatusVisible = false
          this.loading = false
        })
    },
    confirmDelete(orders) {
      this.loading = true
      this.$store.dispatch('order/delete', { items: orders, all: this.isAllSelected })
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.$store.commit('myTable/SET_KEYS', {
              name: this.tableName,
              items: orders.filter(x => !this.selectedRowKeys.includes(x))
                .concat(this.selectedRowKeys.filter(x => !orders.includes(x))),
            })
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" module scoped>
  @import "./style.module.scss";
</style>
