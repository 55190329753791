<template>
  <span>
    <a-dropdown :placement="'bottomLeft'">
      <a-button>Balíkobot
        <a-icon type="down"/>
      </a-button>
      <a-menu slot="overlay">
        <a-menu-item @click="dropParcels()" key="balikobot-5" :disabled="selectedIds.length === 0">
          Odebrat balíky
        </a-menu-item>
        <a-menu-item @click="showShippers()" key="balikobot-1">
          Odeslat informace pro svoz
        </a-menu-item>
        <a-menu-item @click="addParcels()" key="balikobot-2" :disabled="selectedIds.length === 0">
          Přidat balíky
        </a-menu-item>
        <a-menu-item @click="viewParcels()" key="balikobot-4" :disabled="selectedIds.length === 0">
          Sledovat zásilky
        </a-menu-item>
        <a-menu-item @click="printLabels()" key="balikobot-3" :disabled="selectedIds.length === 0">
          Tisknout štítky
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal v-model="visible" @ok="orderParcels()" ok-text="Odeslat vše pro svoz">
      <a-empty v-if="shippersReadyToShip == 0"></a-empty>
      <div v-else :key="item.dly_id" v-for="item in shippersReadyToShip">
        {{ item.dhe_name }} ({{ item.count }}x) <a-button type="link" @click="orderParcels(item.shipper)">Odeslat pro svoz</a-button>
      </div>
    </a-modal>
    <a-modal v-model="visibleOverview" @ok="closeOverview()" ok-text="Ok">
      <a-empty v-if="overviewParcelsContent == 0 || overviewParcelsContent.filter(x => Object.keys(x).length !== 0) == 0"></a-empty>
      <div v-else :key="'overview'+item.id" v-for="item in overviewParcelsContent">
        ID: {{ item.id }}<br>
        Datum: {{ $options.filters.formatDate(item.date) }}<br>
        Název: {{ item.name }}<br>
        Popisek: {{ item.description }}
      </div>
    </a-modal>
  </span>
</template>

<script>
import balikobot from '@/services/balikobot'
import printJS from 'print-js'

export default {
  name: 'my-balikobot-parcels',
  mixins: [balikobot],
  data() {
    return {
      visible: false,
      printedOrderIds: new Set(),
      overviewParcelsContent: [],
      visibleOverview: false,
    }
  },
  props: {
    selectedIds: Array,
  },
  computed: {
    shippersReadyToShip: function () {
      return this.$store.getters['balikobot/shippersReadyToShip']
    },
  },
  methods: {
    closeOverview() {
      this.visibleOverview = false
    },
    addParcels() {
      this.loading = true
      this.$store.dispatch('balikobot/addParcels', { order_ids: this.selectedIds })
        .then(() => {
        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    printSticker(parcels, parcel, index) {
      printJS({
        printable: parcel.label_url,
        type: 'pdf',
        onPrintDialogClose: () => {
          if (!this.printedOrderIds.has(parcel.order_id)) {
            this.$store.dispatch('orderBalikobot/printLabels', parcel.order_id)
            this.printedOrderIds.add(parcel.order_id)
          }
          if (parcels[index + 1]) {
            this.printSticker(parcels, parcels[index + 1], index + 1)
          } else {
            this.printedOrderIds.clear()
          }
        },
      })
    },
    printLabels() {
      this.loading = true
      this.$store.dispatch('balikobot/printLabels', { order_ids: this.selectedIds })
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length !== 0) {
            this.printSticker(response.data, response.data[0], 0)
          }
        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    viewParcels() {
      this.loading = true
      this.$store.dispatch('balikobot/overview', { order_ids: this.selectedIds })
        .then((response) => {
          this.overviewParcelsContent = response.data
          this.visibleOverview = true
        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    dropParcels() {
      this.loading = true
      this.$store.dispatch('balikobot/dropParcels', { order_ids: this.selectedIds })
        .then(() => {
        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showShippers() {
      this.loadShippersReadyToShip()
      this.visible = true
    },
    loadShippersReadyToShip() {
      this.loading = true
      this.$store.dispatch('balikobot/getShippersReadyToShip')
        .then(() => {
        })
        .finally(() => {
          this.loading = false
        })
    },
    orderParcels(shipper) {
      let shippers = [shipper]
      if (shipper === undefined) {
        shippers = this.shippersReadyToShip.map(x => x.shipper)
      }
      this.loading = true
      this.$store.dispatch('balikobot/orderShipment', { shippers: shippers })
        .then(() => {
          this.visible = false
        })
        .catch((response) => {
          this.renderErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
